import * as React from "react"
import withLayout from "../hoc/withLayout";
import { CityPageHeader } from "../components/CityPageHeader";
import MogilevImg from "../images/gorod-mogilev-lg.jpg";
import MogilevImgWebp from "../images/gorod-mogilev-lg.jpg.webp";
import MogilevMapImg from "../images/gorod-mogilev-map.png";
import MogilevMapImgWebp from "../images/gorod-mogilev-map.png.webp";

import IndexPrice from "../components/index/price/Price";
import { CityPageDelivery } from "../components/CityPageDelivery";
import HelpPhone from "../components/HelpPhone";
import Ecology from "../components/Ecology";
import Map from "../components/Map";
import { CityPageAboutUs } from "../components/CityPageAboutUs";



const Mogilev = () => {
  return (
    <>
      <CityPageHeader 
        header={<h1>Чистка сажевого фильтра в Могилёве <small>и Могилёвской области</small></h1>} 
        jpg={MogilevImg} 
        webp={MogilevImgWebp} 
        alt="Чистка сажевого фильтра в Могилёве и Могилёвской области"
      />

      <IndexPrice/>
      
      <CityPageDelivery 
        from="Могилёва и Могилёвской области" 
        jpg={MogilevMapImg}
        webp={MogilevMapImgWebp}
        alt="Доставка сажевых фильтров в Могилёв и Могилёвскую область"
      />

      <HelpPhone/>
      <CityPageAboutUs/>
      <Ecology/>
      <Map/>
    </>
  )
}

export default withLayout(
  Mogilev, 
  "Чистка сажевых фильтров в Могилёве",
  "Удаление 99% загрязнений возникающих из сажи, пепла, масла из двигателя и охлаждающей жидкости, без повреждения картриджа фильтра");
